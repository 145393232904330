<template>
  <div>
    <v-row class="my-3">
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <h5 class="title font-weight-bold">Packages</h5>

          <v-btn
            @click="showCreateDialog()"
            color="primary"
            small
          >
            Create New Package
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <preloader v-if="status.gettingPackages" />

    <v-alert v-if="!packages.length && !status.gettingPackages" type="info" border="left">
      No package created.  
    </v-alert>

    <v-row v-if="packages.length && !status.gettingPackages">
      <v-col  
        v-for="rPackage in orderBy(packages, 'name', 'asc')"
        :key="rPackage.id"
        cols="3"
      >
        <v-card class="box-shadow" outlined>
          <v-card-title class="primary--text">
            <h6 
              @blur="updateTitle(rPackage, $event)"
              :contenteditable="true" 
              class="font-weight-medium flex-grow-1"
            >
              {{ trim(rPackage.name) }}
            </h6>
            <v-spacer></v-spacer>
            <v-btn @click="confirmDelete(rPackage)" icon x-small>
              <v-icon color="error" x-small>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-divider class="mb-5"></v-divider>

            <div v-if="rPackage.types.length">
              <div
                v-for="(type, i) in rPackage.types"
                :key="i"
                class="d-flex justify-space-between align-center mb-2"
              >
                <span>{{ $store.getters['types/getProduct'](type).name }}</span>

                <v-btn @click="removeType(rPackage, type)" icon x-small>
                  <v-icon x-small>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>

            <v-select
              :items="types.filter(f => !rPackage.types.includes(f.id))"
              @input="saveType(rPackage, $event)"
              item-text="name"
              item-value="id"
              class="mt-3"
              hide-details
              outlined
              dense
            ></v-select>

            <div class="mt-5">Priority Level</div>
            <v-radio-group 
              @change="priorityUpdated(rPackage, $event)"
              v-model="rPackage.priority"
              class="mt-0"
              hide-details
              row dense
            >
              <v-radio label="Low" value="low"></v-radio>
              <v-radio label="Medium" value="medium"></v-radio>
              <v-radio label="High" value="high"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- CREATE DIALOG -->
    <create-dialog ref="createDialog" />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :message="`Delete request package ${toDelete.name}?`"
      :deleting="status.deletingPackage"
      :show="deleteDialog"
      @cancel="deleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>
import { orderBy, trim } from 'lodash'
import { mapState, mapActions } from 'vuex'

import CreateDialog from './components/CreatePackageDialog.vue'

export default {
  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    CreateDialog,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      trim,
      orderBy,
      toDelete: {},
      deleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      types: state => state.types.products,
      packages: state => state.request.packages,
      status: state => state.request.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('request',[
      'savePackageField',
      'savePackageType',
      'deletePackage',
      'getPackages',
    ]),

    showCreateDialog() {
      this.$refs.createDialog.showDialog()
    },

    confirmDelete(rPackage) {
      this.toDelete = rPackage
      this.deleteDialog = true
    },

    deleteConfirmed() {
      Promise.resolve(this.deletePackage(this.toDelete))
      .then(() => {
        this.deleteDialog = false
        this.toDelete = {}
      })
    },

    saveType(rPackage, type) {
      if (!rPackage.types.find(t => t == type)) {
        rPackage.types.push(type)
        this.savePackageType(rPackage)
      }
    },

    removeType(rPackage, type) {
      rPackage.types.splice(rPackage.types.indexOf(type), 1)
      this.savePackageType(rPackage)
    },

    updateTitle(rPackage, $event) {
      if (rPackage.name !== $event.target.innerText) {
        this.savePackageField({
          rPackage,
          field: 'name',
          value: $event.target.innerText,
        })
      }
    },

    priorityUpdated(rPackage, value) {
      this.savePackageField({
        value,
        rPackage,
        field: 'priority',
      })
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    if (!this.status.firstLoadAllProducts)
      this.$store.dispatch('types/getAllProducts')
    
    if (!this.status.firstLoadPackages)
      this.getPackages()
  }
}
</script>